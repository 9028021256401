
<!-- not in use, maybe to be deleted -->

<script setup>
    import { ref, inject, watch, computed } from "vue";
    
    const props = defineProps({
        row: { type: Object, required: true },
    });

    const className = computed(() => {
        if (props.row.isSelected) {
            return "afm-list-item-selected";
        } else if (props.row.Disabled) {
            return "afm-list-item-disabled";
        } else if (props.row._recent) {
            return "afm-list-item-recent";
        } 
    });

    const listView = inject("ListView");
</script>

<template>
    <button
        class="d-flex align-items-center gap-3"
        :class="className"
        @click="listView.onClick(row)"
    >
        <template v-if="listView.props.multiselect">
            <div class="flex-shrink-0">
                <input type="checkbox" style="width: 1.25rem; height: 1.25rem;" :checked="row.isSelected" onclick="return false;" />
            </div>
        </template>
        <div class="flex-grow-1">
            <slot />
        </div>
    </button>
</template>

<style scoped>
    button {
        width: 100%;
        background: none;
        border: none;
        display: flex;
        text-align: left;
        padding: 0.75rem 1rem;
        border-bottom: 1px solid var(--bs-gray-300);
        font-size: 0.875em;
        color: var(--bs-black);

        transition: background 200ms ease-in-out;

        &.afm-list-item-selected {
            background: var(--bs-primary-bg-subtle);
        }

        &.afm-list-item-recent {
            background: var(--bs-warning-bg-subtle);
        }

        &:active {
            transition: background 0ms;
            background: var(--bs-gray-200);
        }

        &.afm-list-item-disabled {
            background-color: var(--bs-dark-bg-subtle);
        }
    }
</style>
